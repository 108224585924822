@use "@carbon/react";
@use "@carbon/styles/scss/type";
@use "@carbon/react/scss/colors";

.cds--header__name {
  padding: 0 20px !important;
}

.cds--header__submenu {
  list-style-type: none;
}

.cds--header__menu-bar{
  gap: 2px;
}

.cds--header__menu-item{
  cursor: pointer;
}

.cds--header__menu-title[aria-expanded="true"] + .cds--header__menu {
  inline-size: 100% !important;
}

a.cds--header__menu-item[aria-current="page"]:focus,
a.cds--header__menu-item.cds--header__menu-item--current:focus {
  border: 2px solid transparent !important;
}

a.cds--header__menu-item[aria-current="page"]::after,
.cds--header__menu-item--current::after {
  background-image: linear-gradient(100deg, #5fe9d0 1.95%, #36bffa 100%);
}

@media (min-width: 30rem) {
  .cds--header__nav {
    display: block !important;
  }
}
