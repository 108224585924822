@use "@carbon/react";
@use "@carbon/styles/scss/type";
@use '@carbon/react/scss/colors';

.main{
   background-color: #F4F4F4;
   width: 100%;
   height: 100%;
   min-height: 100vh;
   padding: 72px 16px 16px;
}