@use "@carbon/react";
@use "@carbon/styles/scss/type";
@use "@carbon/react/scss/colors";

.cds--loading__stroke{
  stroke: #B9E6FE !important;
}

.data_table {
  padding: 24px 16px;
  background-color: #fff;
}

.data_table_loading {
  display: flex;
  justify-content: center;
  padding: 24px 16px;
  background-color: #fff;
}

.dx-widget {
  font-family: "TTInterphases", sans-serif !important;
}

.dx-datagrid-headers.dx-datagrid-nowrap {
  background: #f4f4f4 !important;
  font-weight: 600 !important;
  color: rgba(22, 22, 22, 1);
}

.dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
  color: rgba(22, 22, 22, 1) !important;
  background-color: #fff !important;
}

.dx-group-panel-item.dx-datagrid-action.dx-datagrid-drag-action {
  color: rgba(22, 22, 22, 1) !important;
}

.dx-datagrid-summary-item.dx-datagrid-text-content.dx-datagrid-group-text-content {
  color: rgba(22, 22, 22, 1) !important;
  font-weight: 400 !important;
}

.dx-datagrid-summary-item.dx-datagrid-text-content {
  color: rgba(22, 22, 22, 1) !important;
}

.dx-row.dx-column-lines.dx-group-row[aria-expanded="false"] {
  font-weight: 400 !important;
}

.dx-row.dx-column-lines.dx-group-row[aria-expanded="true"] {
  background-color: rgba(208, 226, 255, 1) !important;
}

.dx-datagrid-action.dx-cell-focus-disabled.dx-datagrid-drag-action[aria-sort="descending"],
.dx-datagrid-action.dx-cell-focus-disabled.dx-datagrid-drag-action[aria-sort="ascending"] {
  background-color: #e0e0e0 !important;
}

td {
  text-align: left !important;
}

.dx-row.dx-data-row.dx-column-lines:hover {
  background-color: #edf5ff !important;
}
