@use "@carbon/react";
@use "@carbon/styles/scss/type";
@use "@carbon/react/scss/colors";

.cds--modal-content {
  display: flex !important;
  flex-direction: column !important;
  gap: 30px !important;
  padding-bottom: 20px !important;
  .create_template {
    h3 {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      margin-bottom: 1rem;
      width: 85%;
    }
    .create_template_input {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      // gap: 30px;
      .cds--btn.cds--btn--primary {
        margin-top: 24px;
        background-color: #0f62fe !important;
      }
    }
  }
  .change_template {
    .change_template_items {
      display: flex;
      flex-direction: column;
      h3 {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 10px;
      }
      p {
        padding: 0 10px;
        font-size: 14px;
        margin-bottom: 10px;
      }
      .template_item_edit {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0 10px;
        margin-bottom: 20px;
        .template_item_edit_name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 80%;
        }
        .template_item_edit_buttons {
          display: flex;
          gap: 10px;
        }
        .template_item_edit_buttons_confirm {
          display: flex;
          gap: 10px;
          .cds--btn {
            padding-inline-end: 40px !important;
          }
        }
      }
    }
  }

  .edit_template {
    padding-bottom: 0 !important;
    .edit_template_header {
      padding: 16px;
      border-bottom: 1px solid #d0d5dd;
      h3 {
        font-weight: 600;
        font-size: 22px;
        margin: 10px 0;
      }
      p {
        font-size: 14px;
      }
    }
    .edit_template_update {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      // gap: 20px;
      padding: 16px;
      border-bottom: 1px solid #d0d5dd;
    }
    .edit_template_delete {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      p {
        width: 250px;
        font-size: 14px;
      }
    }
    .edit_template_info {
      padding: 16px;
      border-bottom: 1px solid #d0d5dd;
      h3 {
        font-weight: 600;
        font-size: 18px;
        // margin: 10px 0;
      }
      p {
        margin: 10px 0;
        font-size: 14px;
      }
      .edit_template_info_grouped {
        column-width: 250px;
        max-height: 100px;
        span {
          padding: 5px;
        }
      }
      ul {
        column-width: 150px;
        max-height: 300px;
        li {
          padding: 5px;
        }
      }
    }
  }
}

.cds--modal-content.cds--modal-scroll-content {
  .cds--modal-footer.cds--btn-set {
    .cds--btn.cds--btn--secondary {
      background-color: transparent !important;
      color: #0f62fe !important;
    }
    .cds--btn.cds--btn--primary {
      background-color: #182230 !important;
    }
  }
}

.cds--modal-content.cds--modal-scroll-content:focus {
  outline: none !important;
}

.cds--modal-content--overflow-indicator {
  display: none;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
  text-align: center !important;
}

.dx-checkbox-icon {
  border: 1px solid rgba(0, 0, 0, 0.54) !important;
}
