@use "@carbon/react";
@use "@carbon/styles/scss/type";
@use "@carbon/react/scss/colors";

.lineChart {
  padding: 0 16px;
  background-color: #fff;
  .lineChart_choose {
    display: flex;
    justify-content: center;
    padding-top: 16px;
    &.jin {
      .cds--content-switcher-btn.cds--content-switcher--selected {
        background-color: var(--cds-layer-selected-inverse, #dc2626) !important;
      }
      .cds--content-switcher-btn::after {
        background-color: var(--cds-layer-selected-inverse, #dc2626) !important;
      }
    }
    &.nzt {
      .cds--content-switcher-btn.cds--content-switcher--selected {
        background-color: var(--cds-layer-selected-inverse, #15b79e) !important;
      }
      .cds--content-switcher-btn::after {
        background-color: var(--cds-layer-selected-inverse, #15b79e) !important;
      }
    }
  }
}

.cds--chart-holder {
  font-family: "TTInterphases", sans-serif !important;
  --cds-charts-font-family: "TTInterphases", sans-serif !important;
  --cds-charts-font-family-condensed: "TTInterphases", sans-serif !important;
}

div.cds--cc--legend div.legend-item p {
  font-family: "TTInterphases", sans-serif !important;
  font-weight: 600;
}

.legend-item.additional {
  display: none !important;
}

.cds--content-switcher {
  inline-size: 50% !important;
  max-inline-size: 300px;
}

// .cds--content-switcher-btn.cds--content-switcher--selected{
//   background-color: var(--cds-layer-selected-inverse, #dc2626) !important;
// }

// .cds--content-switcher-btn::after {
//    background-color: var(--cds-layer-selected-inverse, #dc2626) !important;
// }
