@use "@carbon/react";

@font-face {
  font-family: "TTInterphases";
  src: 
    url("./fonts/TT_Interphases/webfontkit/tt_interphases_black-webfont.woff")
      format("woff"),
    url("./fonts/TT_Interphases/webfontkit/tt_interphases_black-webfont.woff2")
      format("woff2");
  font-weight: bolder;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0400-04FF, U+4E00-9FFF;
}

@font-face {
  font-family: "TTInterphases";
  src: 
    url("./fonts/TT_Interphases/webfontkit/tt_interphases_bold-webfont.woff")
      format("woff"),
    url("./fonts/TT_Interphases/webfontkit/tt_interphases_bold-webfont.woff2")
      format("woff2");
  font-weight: bold;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0400-04FF, U+4E00-9FFF;
}

@font-face {
  font-family: "TTInterphases";
  src: 
    url("./fonts/TT_Interphases/webfontkit/tt_interphases_regular-webfont.woff")
      format("woff"),
    url("./fonts/TT_Interphases/webfontkit/tt_interphases_regular-webfont.woff2")
      format("woff2");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0400-04FF, U+4E00-9FFF;
}

body {
  margin: 0;
  padding: 0;
  font-family: "TTInterphases", sans-serif !important;
}
