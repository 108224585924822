@use "@carbon/react";
@use "@carbon/styles/scss/type";
@use "@carbon/react/scss/colors";

.user_toolbar {
  padding: 24px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  .user_toolbar_filters {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 80%;
    .cds--list-box__wrapper {
      flex-grow: 1;
    }
    .cds--multi-select__wrapper > .cds--label {
      display: none;
    }
  }
  .excelExport{
    padding-right: 40px !important;
  }
}

.cds--multi-select .cds--list-box__menu[aria-labelledby="login-label"],
.cds--multi-select .cds--list-box__menu[aria-labelledby="PID-label"],
.cds--multi-select .cds--list-box__menu[aria-labelledby="club-label"],
.cds--multi-select .cds--list-box__menu[aria-labelledby="league-label"] {
  min-inline-size: max-content !important;
}

.cds--list-box__menu-item__option{
  padding-inline-end: 0.5rem !important;
}

.excelExport.nzt {
  background-color: var(--button-button-primary, #15b79e) !important;
}
.excelExport.jin {
  background: linear-gradient(
    279deg,
    #dc2626 6.35%,
    #ef4444 140.89%
  ) !important;
}

@media (max-width: 1300px) {
  .user_toolbar {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;
    .user_toolbar_filters {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      .cds--date-picker-container.cds--date-picker--nolabel,
      .cds--date-picker__input.cds--date-picker__input--lg {
        inline-size: calc((100vw - 64px) / 2) !important;
      }
    }
    .excelExport {
      width: 100%;
      max-inline-size: none !important;
    }
  }
}
