@use "@carbon/react";
@use "@carbon/styles/scss/type";
@use "@carbon/react/scss/colors";

.cds--list--unordered {
  padding: 10px !important;
  .cds--list__item{
    span{
      b{
        font-weight: 700 !important;
      }
    }
  }
}