@use "@carbon/layout" as *;
@use "@carbon/type" as *;

.auth {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 400px;
    .bg {
      width: 100%;
    }
    img {
      display: flex;
      width: 113.075px;
      height: 48px;
      justify-content: center;
      align-items: center;
      z-index: 2;
    }

    .formGroup {
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 16px;
      width: 100%;

      legend {
        display: none;
      }

      h3 {
        font-weight: 600 !important;
        @include type-style("heading-03");
      }

      p {
        color: var(--text-text-secondary, var(--text-text-secondary, #525252));
        margin-top: 4px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
      form {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        margin-top: 16px;

        .cds--password-input-wrapper {
          margin-top: 24px;
        }
      }
      .newPassword{
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        align-self: stretch;

        .cds--password-input-wrapper {
          margin-top: 24px;
        }
      }
      .formLink {
        margin-top: 4px;
        color: var(--link-link-primary, #0f62fe);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.32px;
        cursor: pointer;
      }
      .formButton {
        padding: 15px 16px;
        width: 100%;
        max-inline-size: none;
        margin-top: 24px;
      }
      .formButton.nzt {
        background: linear-gradient(100deg, #34d399 1.95%, #38bdf8 100%);
      }
      .formButton.jin {
        background: linear-gradient(279deg, #dc2626 6.35%, #ef4444 140.89%);
      }
      .formButtonSet {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .formButton {
          padding: 15px 16px;
          max-inline-size: none;
          margin-top: 24px;
          width: 48%;
        }
        .formButton.nzt{
          background: linear-gradient(100deg, #34d399 1.95%, #38bdf8 100%);
        }
        .formButton.jin{
          background: linear-gradient(279deg, #DC2626 6.35%, #EF4444 140.89%);
        }
        .formButtonCancel {
          padding: 15px 16px;
          max-inline-size: none;
          margin-top: 24px;
          width: 48%;
          border-color: transparent;
          &:hover {
            background-color: transparent;
            color: #0f62fe;
          }
        }
      }
    }
  }
}

.auth.nzt {
  background: url("../../assets/nzt/BGShape.png"),
    linear-gradient(100deg, #5fe9d0 1.95%, #36bffa 100%);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.auth.jin {
  background-color: #162033;
  background-image: url("../../assets/jin/coinsdddd_3.png"),
    url("../../assets/jin/coinsdddd_5.png"),
    url("../../assets/jin/coinsdddd_4.png");
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: top, top right, bottom;
  .group {
    .bg {
      border-radius: 12px;
      padding: 16px;
      width: 100%;
      height: 100%;

      background: linear-gradient(38deg, #b91c1c 15.95%, #dc2626 84.05%);
      box-shadow: 0px 10px 13px 0px rgba(15, 23, 42, 0.15),
        0px -2px 1px 0px rgba(153, 27, 27, 0.3) inset,
        0px 2px 1px 0px rgba(255, 255, 255, 0.25) inset,
        0 -60px 650px 100px rgba(229, 53, 53, 0.59);
      display: flex;
      align-items: center;
      justify-content: center;
      .formGroup.jin {
        width: 400px;
        height: 100%;
      }
    }
  }
}

.notification{
  margin-top: 16px;
}

.cds--inline-notification__text-wrapper{
  gap: 10px;
}

@media (max-width: 500px) {
  .auth.jin {
    .group.jin {
      width: 100%;
      .bg {
        height: 100%;
        .formGroup.jin {
          width: 100%;
        }
      }
    }
  }
}
